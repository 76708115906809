import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, finalize, catchError, switchMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { CurrentUser, UserRoles, Permission, setPermissions } from "../models";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { AuthService } from "@auth0/auth0-angular";
import { AppTranslationService, ErrorHandlerService } from "../services";

export interface ILoginContext {
    username: string;
    password: string;
    remember?: boolean;
}
const credentialsKey = "credentials";
@Injectable({ providedIn: "root" })
export class AuthenticationService {
    public replaceUrl: string;
    public token: string;
    private _credentials: CurrentUser;
    public credentials: BehaviorSubject<CurrentUser>;

    public userRoles: UserRoles[];
    public permissions: Permission[];

    constructor(
        private readonly httpClient: HttpClient,
        private readonly router: Router,
        public auth: AuthService
    ) {
        this.credentials = new BehaviorSubject<CurrentUser>(undefined);
    }

    /**
     * Gets the user credentials.
     * @return {Credentials} The user credentials or null if the user is not authenticated.
     */
    public get currentUser(): CurrentUser {
        const role =
            this._credentials &&
            this._credentials.user &&
            this._credentials.user.userRoles &&
            this._credentials.user.userRoles[0]
                ? this._credentials.user.userRoles[0].key
                : this.userRoles && this.userRoles[0]
                ? this.userRoles[0].key
                : undefined;
        this.permissions = setPermissions(role);
        return this._credentials;
    }

    public isAuthenticated(
        redirectToLogin: boolean = true
    ): Observable<boolean> {
        var env=environment.apiBaseUrl;
        return this.auth.isAuthenticated$.pipe(
            switchMap((isAuthenticated) => {
                if (isAuthenticated) {
                    if (!this.currentUser) {
                        return this.httpClient
                            .get(
                                `${env}/CslPlatform3/Profile`,
                                {}
                            )
                            .pipe(
                                switchMap((user: CurrentUser) => {
                                    this.setUser(user, true);
                                    if (
                                        !user.user.authorized ||
                                        !user.user.emailVerified
                                    ) {
                                        return of(false).pipe(
                                            map((_) => {
                                                if (redirectToLogin)
                                                    this.router.navigate([
                                                        "/login",
                                                    ]);
                                                return _;
                                            })
                                        );
                                    }
                                    var resultObs = of(true);
                                    if (user.user.languageCE) {
                                    }
                                    return of(true);
                                })
                            );
                    } else {
                        return of(this.currentUser.user.authorized);
                    }
                } else {
                    return this.auth.loginWithRedirect().pipe(
                        map((result) => {
                            return true;
                        })
                    );
                }
            }),
            catchError((error) => {
                let er=error;
                return of(false);
            })
        );
    }

    public logout(redirectToPreviousPath?: boolean) {
        let url = location.href;
        return this.auth.logout();
    }

    /**
     * Sets the user credentials.
     * The credentials may be persisted across sessions by setting the `remember` parameter to true.
     * Otherwise, the credentials are only persisted for the current session.
     * @param {ILoginContext=} login The user credentials.
     * @param {boolean=} remember Remember the credentials.
     */
    private setCredentials(credentials?: CurrentUser, remember?: boolean) {
        if (this._credentials === credentials) return;
        this._credentials = credentials;
        if (this.credentials && this.credentials.value !== this._credentials) {
            this.credentials.next(this._credentials);
        }
        this.credentials = new BehaviorSubject(this._credentials);
    }

    getToken(options?: any): Observable<any> {
        return this.auth.getAccessTokenSilently(options);
    }

    private setUser(authResponse: CurrentUser, remember?: boolean) {
        if (
            authResponse &&
            authResponse.user &&
            authResponse.user.userRoles &&
            authResponse.user.userRoles.length > 0
        ) {
            this.userRoles = authResponse.user.userRoles;

            this.permissions = setPermissions(this.userRoles[0].key);
        }
        this.setCredentials(authResponse, remember);
    }
}
